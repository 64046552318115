<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgButton
			:type="''"
			:text="'OK'"
			:isCircle="false"
		></DgButton>

	2021-09-24	init

-->

<template>
	<a class="DgButton shadow shadow--button noSelect" :class="elmClasses" :href="href" :target="target">
		<span class="DgButton__inner">
			<MhIcon class="DgButton__icon" :type="type" v-if="type"></MhIcon>
			<span class="DgButton__text" v-html="text"></span>
		</span>
	</a>
</template>

<script>
	// @ is an alias to /src
	//import EventBus from '@/helper/EventBus.js'
	import MhIcon from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhIcon/v2/MhIcon.vue'

	export default {
		name: 'DgButton',
		components: {
			MhIcon,
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			type: {
				type     : [String],
				default  : '',
				required : false,
			},
			text: {
				type     : [String],
				default  : '',
				required : false,
			},
			href: {
				type     : [String, Boolean],
				default  : false,
				required : false,
			},
			target: {
				type     : [String, Boolean],
				default  : false,
				required : false,
			},
			isCircle: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			isOutlined: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isOutlined ) classes.push( this.$options.name + '--isOutlined')
				else classes.push( this.$options.name + '--isFilled')

				if( this.isCircle ) classes.push( this.$options.name + '--isCircle')

				if( this.text ) classes.push( this.$options.name + '--hasText')

				if( this.type ) classes.push( this.$options.name + '--type-' + this.type)

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgButton { // debug
		[showBorders2] & {}
		//background-color: fade( red, 15 );
		//&__inner { background-color: fade( red, 50 ); }
	}
	.DgButton { // vars
		--color : var(--app-button-color);
		--bgColor : var(--swatch-cta);
		--borderColor : var(--swatch-cta);
	}
	.DgButton { // layout
		position: relative;
		display: inline-flex;
		height: var(--app-button-height);
		min-width: var(--app-button-width);
		overflow: hidden;

		&__inner {
			position: relative;
			display: flex;
			flex-grow: 1;
			justify-content: center;
			align-items: center;
		}
		&__text {
			vertical-align: baseline;
		}
	}
	.DgButton { // styling
		font-size: var(--app-gui-fontSize-s);
		line-height: 1em;
		color: var(--color);
		transition: var(--app-button-transition);
		background-color: var(--bgColor);
		border-radius: calc( var(--app-button-height) * 0.5 );
		padding: var(--app-button-padding);

		&:hover { transform: var(--app-button-hoverTransform); }
		&:active { transform: var(--app-button-activeTransform); }

		&__icon { flex-shrink: 0; }
		&--hasText &__icon { margin-right: 0.35em; }

		&--isCircle { padding: 0; max-width: var(--app-button-width); }
		&--isOutlined { background-color: transparent; color: var(--borderColor); border: 2px solid var(--borderColor); }

		//&--isOutlined:hover { background-color: var(--swatch-cta-darken);}
		&--isFilled:hover { background-color: var(--swatch-cta-darken); }

		//&--isOutlined &__inner {   background-color: transparent; }
		//&--isFilled &__inner { border-top: 1px solid fade(white, 0); border-bottom: 1px solid fade(white, 0); color: white; background-color: var(--swatch-cta); }

		//&--hasText &__inner { padding: 0 1em; }
		//&--hasText .MhIcon { margin-right: 0.5em; }

		//.MhIcon { --stroke-width : 2px;	}

		// visual fix to adjust text baseline to hamburger line
		&--type-menu &__text { text-transform: uppercase; }
		&--type-menu .MhIcon { font-size: 1.2em; }

		// enlarge some icon for better matching together
		&--type-youtube .MhIcon { font-size: 1.35em; }
		&--type-twitter .MhIcon { font-size: 1.35em; }
		&--type-facebook .MhIcon { font-size: 1.5em; }
		&--type-instagram .MhIcon { font-size: 1.35em; }

		&--type-search .MhIcon { font-size: 1.5em; }
		&--type-arrow-left .MhIcon { font-size: 1.5em; }
		&--type-arrow-right .MhIcon { font-size: 1.5em; }
		&--type-plus .MhIcon { font-size: 1.5em; }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
